.auth {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.auth-box {
  height: 400px;
  width: 525px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth-btn {
  display: flex;
  grid-area: 1rem;
}
.auth-btn button:nth-child(1) {
  width: 100%;
  padding: 12.5px 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid gainsboro;
  color: rgb(124, 106, 106);
}

.auth-btn button:nth-child(2) {
  width: 100%;
  padding: 12.5px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid gainsboro;
  color: rgb(124, 106, 106);
}

.auth-btn button {
  transition: 0.2s;
}
.auth-btn button:hover {
  color: grey;
}
.auth-btn button:focus {
  background-color: #e0e0e0;
  color: black;
  transform: scale(45deg);
}

.google-btn button {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  height: 3.3rem;
  border-radius: 4px;
  border: 1px solid #1976d2;
  color: #1976d2;
  font-weight: 500;
}
.g-logo {
  color: #1976d2;
}
.google-btn button:hover {
  border: 2px solid #1976d2;
}
.hr {
  height: 1rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(190, 176, 176);
}
.ors {
  margin-top: 0.2rem;
}
.auth-info {
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group-g {
  position: relative;
  border: 1px solid #999;
  width: 100%;
  border-radius: 5px;
}

.form-group-g > label {
  position: absolute;
  top: -12px;
  left: 25px;
  background-color: white;
  color: #7e7a7a;
  padding: 0 5px;
  font-size: 14px;
}

.form-group-g > input {
  border: none;
  border: 0;
  padding: 16px 12px;
  width: 100%;
  border: 1px solid transparent;
}
.form-group-g input:hover {
  border: 1px solid black;
}

.form-group-g input:focus {
  border: 1px solid #1976d2;
}

.log-t {
  padding: 10px 5px;
  background-color: #1976d2;
  color: white;
  border-radius: 5px;
  border: none;
}

.log-pass {
  width: 40%;
  margin: auto;
  background-color: transparent;
  color: #1976d2;
  border: none;
}

.reset {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pass-btn {
  padding: 12px 5px;
  background-color: #1976d2;
  color: white;
  border-radius: 5px;
  border: none;
}
.back-btn {
  margin-top: 1rem;
  width: 50%;
  margin: auto;
  background-color: transparent;
  border: none;
  color: #1976d2;
}

@media screen and (max-width: 700px) {
  .auth-box {
    width: 400px;
    margin: 0px 15px;
  }
  .log-pass {
    width: 100%;
  }
}
