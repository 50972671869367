* {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.loaders {
  height: 87vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mains {
  position: relative;
  height: 100vh;
}
.headers {
  position: sticky;
  width: 100%;
  height: 85px;
  left: 0px;
  top: 0px;
  background: #162a48;
  z-index: 999;
  box-shadow: 0px 4px 5px gray;
}
.containers {
  width: 95%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.logo {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 2rem;
}
.logo-main {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.logo-main h4 {
  color: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18.4459px;
}
.my-icon {
  color: white;
  margin-right: 0.5rem;
}
.item a {
  text-decoration: none;
}
.extra-btns:hover {
  color: black;
}

.logo img {
  width: 25px;
}
.my-navs {
  margin-left: -3rem;
}
.extra {
  margin-top: 0.8rem;
}
.logo h3 {
  width: 54px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18.4459px;
  line-height: 128.02%;
  /* identical to box height, or 15px */

  text-decoration-line: line-through;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.list-items ul {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.list-items ul li a {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 128.02%;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.list-items ul li a.active {
  color: #0080ff;
}

.list-items ul li a:hover {
  color: #0080ff;
  transition: 0.3s;
}

.list-items {
  display: flex;
  gap: 1.5rem;
}
.n-image {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid white;
  padding: 5px;
}
.chakra {
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-left: -10px;
}
.btns {
  height: 100%;
  display: flex;
  align-items: center;
}

.btns button {
  width: 114px;
  height: 39px;
  color: white;
  background: #0080ff;
  border: 0.863641px solid #4e22fe;
  border-radius: 7px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

/* prising */
.prising {
  display: flex;
  min-height: 75vh;
  margin-top: 4rem;
}
.p-body {
  width: 580px;
}
.p-text h1 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 3rem;
}

.p-text p {
  width: 95%;
  margin: auto;
}
.p-card {
  width: 95%;
  margin: auto;
}

.l-card {
  width: 48%;
  height: 370px;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;
}
.r-card {
  width: 48%;
  height: 370px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 6px -3px,
    rgba(0, 0, 0, 0.14) 0px 10px 14px 1px, rgba(0, 0, 0, 0.12) 0px 4px 18px 3px;
  overflow: hidden;

  border: 3px solid rgb(25, 118, 210);
  background-color: rgb(245, 250, 255);
}
.loaderss {
  height: 60vh;
  align-items: center;
}

.timer {
  overflow: hidden;
  position: relative;
  min-height: 86vh;
  color: white;
  background-color: #0a6cf1;
}
.main-timer span {
  font-size: 20px;
}
.main-timer {
  width: 600px;
  height: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 5px;
  box-shadow: 2px 2px 3px gray;
  background-color: black;
}

@media screen and (max-width: 1200px) {
  .logo-main h4 {
    font-size: 18.4459px;
  }
}
@media screen and (max-width: 1100px) {
  .speicial {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .mains {
    position: relative;
    height: 200vh;
  }
  .main-timer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 400px;
  }
}
@media screen and (max-width: 600px) {
  .mains {
    position: relative;
    height: 300vh;
  }
  .logo h3 {
    font-size: 15.4459px;
  }
  .btns button {
    width: 100px;
    height: 35px;
    margin-right: 0.5em;
  }
  .p-card {
    flex-direction: column;
  }

  .l-card {
    height: 260px;
  }
}

.ellepse {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #0080ff;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
}
